
/* Run functions on window.load */
$(window).load(function() {

});

/* Run functions on document.ready */
$(document).ready(function(){

    /* Admin bar */
    adminBar();

    /* Declare custom path */
    var cdnPath = 'https://cdn.adnetcms.com/lib';
    var themePath = '/_themes/design2015';

    /* Form pop up */
    var $subscribe = $('#subscribe-button');
    var $subscribeClose = $('#subscribe-form-close');
    $subscribe.click(function(e) {
        $('#subscribe-form').show();
        e.preventDefault();
    });

    $subscribeClose.click(function(e) {
        $('#subscribe-form').hide();
        e.preventDefault();
    });

    /* Feature box click */
    var clickBox = $('.clickbox');
    if (clickBox.length){
        clickBox.click(function(){
            var clickBoxURL = $(this).data('url');
            var clickBoxTarget = $(this).data('target');
            if (clickBoxTarget == 'external'){
                window.open(clickBoxURL);
            } else {
                location.href = clickBoxURL;
            }
        });
    }

    /* uiKit Modal window, toggler and builder */
    function createUiModal(id,title,url){
        var modalBox = '<div id="modal-' + id + '" class="uk-modal">';
            modalBox  += '<div class="uk-modal-dialog">';
            modalBox  += '<div class="uk-modal-header"><h2>Newmarket Gold Inc.</h2></div>';
            modalBox  += '<h3>' + title + '</h3>';
            modalBox  += '<div class="uk-modal-footer">';
            modalBox  += '</div>';
            modalBox  += '</div>';
            modalBox  += '</div>';
            $('body').append(modalBox);
    }

    /* Print button thingy */
    var $printButton = $('#print-button');
    $printButton.on('click', function(e){
        e.preventDefault();
        window.print();
    });

    /* Format tables and lists */
    var mainDiv = $('div#page-content');
    if (mainDiv.length){
        // Convert tables to uikit style table
        mainDiv.find('table').not('.exception').each(function(){
            var table = $(this);
            table.wrap('<div class="uk-overflow-container" />');
            //table.addClass('uk-table uk-table-striped');
        });
    }

  /* FancyBox set up */
  if ($('.fancybox, .fancybox-thumb, .fancybox-iframe, .gallery-page, .ext-video').length > 0){
    $.when(
      $.getScript( cdnPath + "/fancybox/2.1.5/jquery.fancybox.pack.js" ),
      $.getScript( cdnPath + "/fancybox/2.1.5/helpers/jquery.fancybox-thumbs.js" ),
      $.getScript( cdnPath + "/flowplayer/5.4.6/flowplayer.min.js" ),
      $.Deferred(function( deferred ){
        $( deferred.resolve );
      })
    ).done(function(){
      var fbRegular = {openEffect: 'fade', closeEffect: 'fade', prevEffect: 'fade', nextEffect: 'fade', prevSpeed: 'slow', nextSpeed: 'slow', helpers: {title : {type : 'inside'}}};
      var fbGallery = {openEffect: 'fade', closeEffect: 'fade', prevEffect: 'fade', nextEffect: 'fade', prevSpeed: 'slow', nextSpeed: 'slow', mouseWheel: true, helpers: {title : {type : 'inside'}, thumbs: {width: 100, height: 100}}};
      var fbFlickr = {openEffect: 'fade', closeEffect: 'fade', prevEffect: 'fade', nextEffect: 'fade', prevSpeed: 'slow', nextSpeed: 'slow', mouseWheel: true, helpers: {title : {type : 'inside'}, thumbs: {width: 100, height: 100}}, afterLoad: function(){this.title = this.element.data('caption')}};
      var fbIFrame = {type: 'iframe', openEffect: 'none', closeEffect: 'none', prevEffect: 'fade', maxWidth: 770, maxHeight: 575, fitToView: false, width: '100%', height: '100%', autoSize: false, closeClick: false};
      $('a.fancybox').fancybox(fbRegular);
      $('a.fancybox-thumb').fancybox(fbGallery);
      $('a.fancybox-flickr').fancybox(fbFlickr);
      $('a.fancybox-iframe').fancybox(fbIFrame);

      /* Set up external video box */
      $('.ext-video').fancybox({openEffect: 'fade', closeEffect: 'fade', type: 'iframe', width: 800, height: 580, scrolling: 'no', autoDimensions: false, fitToView: false });

      /* Video player: Flowplayer */
      if ($('.video').length > 0){
        $('a.video').click(function(e) {
          e.preventDefault();
          var container = $('<div class="video-box"></div>');
          container.flowplayer( cdnPath + '/flowplayer/5.4.6/flowplayer.swf', $(this).attr('href'));
          $.fancybox({ openEffect: 'fade', closeEffect: 'fade', content: container, width: 800, height: 580, scrolling: 'no', autoDimensions: false });
        });
      }
    });
  }


});
